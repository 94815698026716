<template>
  <b-modal id="modal-detail-no-content" size="lg" hide-footer>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="InfoIcon" class="mr-05" />
        <span class="fs-18">
          Detail No Content pada Scraper "<strong>{{ scraper }}</strong
          >"
        </span>
      </div>
    </template>
    <loader v-if="is_loading" />
    <div v-else class="scroller mb-2" style="max-height: 30rem;">
      <div v-for="item in data" :key="item._id">
        <b-card no-body class="border border-info">
          <div class="p-1">
            <b-card-title
              class="mb-1 text-justify"
              style="font-size: 14px !important"
            >
              {{ item.title }}
            </b-card-title>
            <div class="d-flex align-items-center">
              <i class="bi bi-globe" style="margin-right: 5px"></i>
              <span>{{ item.account }}</span>
              <i class="bi bi-calendar ml-3" style="margin-right: 5px"></i>
              <span>{{ parseDate(dateConvertUTC7(item.date)) }}</span>
            </div>
            <div class="mt-2">
              {{ item.content ? item.content : "--Konten Tidak Tersedia!--" }}
            </div>
          </div>
          <div class="row p-1">
            <div class="col-md-6">
              <a
                :href="item.url || item.file_source[0] || item.file_source"
                target="_blank"
              >
                <b-button block size="sm" variant="info">
                  <i class="bi bi-box-arrow-up-right"></i> Kunjungi
                </b-button>
              </a>
            </div>
            <div class="col-md-6">
              <b-button
                block
                size="sm"
                variant="secondary"
                @click="
                  copyURL(
                    item.url || item.file_source[0] || item.file_source,
                    item._id
                  )
                "
              >
                <span v-if="iscopied && id_copied == item._id">
                  <i class="bi bi-clipboard-check"></i>
                  URL Berhasil Disalin
                </span>
                <span v-else> <i class="bi bi-clipboard"></i> Salin URL </span>
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
      <b-button
        block
        class="mb-1"
        variant="outline-info"
        @click="loadMore()"
        v-if="data && data.length < page_info.total"
      >
        <b-spinner v-if="is_loading_more" small variant="info" />
        <i v-else class="bi bi-arrow-down"></i>
        Lihat Lebih Banyak
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import loader from "../loader.vue";
import helper from "@/helper/helper";

export default {
  name: "detailNoContentModal",
  components: {
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BSpinner,
    loader,
  },
  props: {
    id: String,
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      scraper: "",
      is_loading: true,
      is_loading_more: false,
      page: 1,
      items: 10,
      data: null,
      iscopied: false,
      id_copied: null,
      page_info: {
        limit: 10,
        page: 1,
        total: 0,
        _id: null,
      },
    };
  },
  methods: {
    getData(id, is_loadmore = false, from_date = null, to_date = null) {
      if (from_date) {
        this.from_date = from_date;
      }
      if (to_date) {
        this.to_date = to_date;
      }

      this.scraper = id;
      if (!is_loadmore) {
        this.page = 1;
        this.is_loading = true;
      } else {
        this.is_loading_more = true;
      }
      let params = {
        from_date: this.from_date,
        to_date: this.to_date,
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = `${process.env.VUE_APP_API_URL}stream/no-content/${id}?${query}`;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          if (is_loadmore) {
            for (let i = 0; i < res.data.no_content_data.length; i++) {
              this.data.push(res.data.no_content_data[i]);
            }
            this.page_info.total = res.data.pagination_info.count;
          } else {
            this.data = res.data.no_content_data;
            this.page_info.total = res.data.pagination_info.count;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading_more = false;
          this.is_loading = false;
        });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    dateConvertUTC7(date) {
      return helper.dateConvertUTC7(date);
    },
    copyURL(url, id) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.iscopied = true;
          this.id_copied = id;
          setTimeout(() => {
            this.iscopied = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Gagal menyalin teks ke clipboard: ", error);
        });
    },
    loadMore() {
      this.page++;
      this.getData(this.scraper, true);
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
